/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const BeVietnamPro_100Thin = require('./BeVietnamPro_100Thin.ttf');
export const BeVietnamPro_100Thin_Italic = require('./BeVietnamPro_100Thin_Italic.ttf');
export const BeVietnamPro_200ExtraLight = require('./BeVietnamPro_200ExtraLight.ttf');
export const BeVietnamPro_200ExtraLight_Italic = require('./BeVietnamPro_200ExtraLight_Italic.ttf');
export const BeVietnamPro_300Light = require('./BeVietnamPro_300Light.ttf');
export const BeVietnamPro_300Light_Italic = require('./BeVietnamPro_300Light_Italic.ttf');
export const BeVietnamPro_400Regular = require('./BeVietnamPro_400Regular.ttf');
export const BeVietnamPro_400Regular_Italic = require('./BeVietnamPro_400Regular_Italic.ttf');
export const BeVietnamPro_500Medium = require('./BeVietnamPro_500Medium.ttf');
export const BeVietnamPro_500Medium_Italic = require('./BeVietnamPro_500Medium_Italic.ttf');
export const BeVietnamPro_600SemiBold = require('./BeVietnamPro_600SemiBold.ttf');
export const BeVietnamPro_600SemiBold_Italic = require('./BeVietnamPro_600SemiBold_Italic.ttf');
export const BeVietnamPro_700Bold = require('./BeVietnamPro_700Bold.ttf');
export const BeVietnamPro_700Bold_Italic = require('./BeVietnamPro_700Bold_Italic.ttf');
export const BeVietnamPro_800ExtraBold = require('./BeVietnamPro_800ExtraBold.ttf');
export const BeVietnamPro_800ExtraBold_Italic = require('./BeVietnamPro_800ExtraBold_Italic.ttf');
export const BeVietnamPro_900Black = require('./BeVietnamPro_900Black.ttf');
export const BeVietnamPro_900Black_Italic = require('./BeVietnamPro_900Black_Italic.ttf');
